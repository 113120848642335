<template  >
  <div  class=" about mt-10 md:mx-28 lg:mx-60 divide-y divide-gray-300">
    <div class="content leading-8 mx-10">
      <h1 class="text-4xl">About <span class="italic">me</span></h1>
      <div class="content text-xl mt-6">
        <p>
          Hi, I'm Sameer! I'm interested in a lot of things, but specially tech,
          truth, improving myself and improving the world. One of my life’s
          goals is self-actualization, and I’m on the mission to show as many
          people as I can that they can realize their potential, too.
        </p>
        <p>
          I taught myself how to write code and building websites to help people
          become better at what they do. I am currently a software
          engineer(self-taught) building a enterprise grade SaaS application.
        </p>
      </div>
      <h1 class="mt-10 text-4xl">About my <span class="italic">blog</span></h1>
      <div class="content text-xl mt-6">
        <p>
          This blog is a collection of my thoughts on various ideas I've found
          valuable for being happy, improving my life, or understanding the
          world. A lot of them will be focus on self-improvement and
          rationality. (Anything and everything on this blog is obviously my
          personal view!)
        </p>
      </div>
    </div>
    <div class="my-10 mx-10">
      <p class="mt-10 text-sm">designed & developed with <span class="text-red-600"><i class="fa fa-heart" aria-hidden="true"></i> </span> & <i  class="fa">&#xf0f4;</i> </p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "About",
  data(){
    return {
    };
  },
  components: {},
};
</script>

